export const baseurl = process.env.REACT_APP_BASE_URL;
export const baseurlSocket = process.env.REACT_APP_SOCKET_URL;

let url = `${baseurl}/api/v1`;
let lottery = `${url}/lottery`;

const ApiConfig = {
  connectWallet: `${url}/user/connectWallet`,
  getProfile: `${url}/user/getProfile`,
  editProfile: `${url}/user/editProfile`,
  addReffferalCode: `${url}/user/addReffferalCode`,

  //AuthFlow
  login: `${url}/user/login`,
  signup: `${url}/user/signup`,
  forgotPassword: `${url}/user/forgot-password`,
  verifyOtp: `${url}/user/verify-otp`,
  resetPassword: `${url}/user/reset-password`,
  resendOTP: `${url}/user/resendOTP`,

  //Admin Category
  addCategory: `${url}/h2h/addCategory`,
  getCategory: `${url}/h2h/getCategory`,
  deleteCategory: `${url}/h2h/deleteCategory`,
  liveMatchData: `${url}/h2h/liveMatchData`,

  //Admin Fee
  getAdminFee: `${url}/admin/getAdminFee`,
  editAdminFee: `${url}/admin/editAdminFee`,
  topWinner: `${url}/admin/topWinner`,
  popularPlayer: `${url}/admin/popularPlayer`,

  //Admin Question
  questionLIst: `${url}/h2h/questionLIst`,
  addQuestion: `${url}/h2h/addQuestion`,
  deleteQuestion: `${url}/h2h/deleteQuestion`,
  checkGameStatus: `${url}/h2h/checkGameStatus`,

  ///User h2h
  liveGame: `${url}/h2h/liveGame`,
  betPlace: `${url}/h2h/betPlace`,
  betPlaceV1: `${url}/h2h/betPlaceV1`,
  distributeAmount: `${url}/h2h/distributeAmount`,
  graph_data: `${url}/h2h/graphData`,

  //LOTTERY//
  createRoom: `${lottery}/createRoom`,
  buyTickets: `${lottery}/buyTickets`,
  onGoingEvent: `${lottery}/onGoingEvent`,
  createLottory: `${lottery}/createLottory`,
  listLottory: `${lottery}/listLottory`,
  winningAmount: `${lottery}/winningAmount`,

  amountClaim: `${lottery}/amountClaim`,

  //referral
  directIndirectUserList: `${url}/user/directIndirectUserList`,

  //derby game
  scheduleEvent: `${url}/derby/scheduleEvent`,
  derbyhistory: `${url}/derby/history`,
  eventLists: `${url}/derby/eventLists`,
  joinEventV2: `${url}/derby/joinEventV2`,
  leaderboards: `${url}/derby/leaderboards`,
  leaderboardList: `${url}/derby/leaderboardList`,
  winnerHorse: `${url}/derby/winnerHorse`,

  // Admin Static Content
  listStaticContent: `${url}/statics/get-all`,
  editStaticContent: `${url}/static/editStaticContent`,
  contactUs: `${url}/contact/contactUs`,

  // Admin Faq Management
  addFaq: `${url}/static/addFAQ`,
  editFaq: `${url}/static/editFaq`,
  deleteFaq: `${url}/static/deleteFaq`,
  listFAQ: `${url}/static/listFAQ`,

  ///marketGraph///
  marketPriceHistoricalData: `${url}/h2h/marketPriceHistoricalData`,

  ///New apis///
  viewProfile: `${url}/user/view-profile`,
  viewWallet: `${url}/user/view-wallet`,
  editProfileApi: `${url}/user/edit-profile`,
  socialLogin: `${url}/user/socialLogin`,
  getCoin: `${url}/user/getCoin`,
  depositMoney: `${url}/user/deposite-money`,
  withdrawamount: `${url}/user/withdrawamount`,
  transactionHistory: `${url}/user/transactionHistory`,
  getUsername: `${url}/user/getUsername`,
  refferalHistory: `${url}/user/refferalHistory`,
  getDepositBalence: `${url}/user/getDepositBalence`,
  getMinimumDeposite: `${url}/user/getMinimumDeposite`,
  viewTransactons: `${url}/user/viewTransactons`,
  verifyWithdrawRequest: `${url}/user/verifyWithdrawRequest`,
  LoginEmail: `${url}/user/LoginEmail`,

  ////Admin////
  walletList: `${url}/admin/walletList`,
  withdrwaList: `${url}/admin/withdrwaList`,
  listUser: `${url}/admin/list-user`,
  approveRejectWithdraw: `${url}/admin/approveRejectWithdraw`,
};
export default ApiConfig;
