import { Box, Typography, makeStyles } from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
  mainLoading: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    border: "3px solid #fff",
    borderTopColor: "transparent",
    animationName: "$spin",
    animationDuration: "1s",
    animationIterationCount: "infinite",
    animationTimingFunction: "linear",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

export default function ButtonLoader(props) {
  const classes = useStyles();
  return (
    <Box
      className={classes.mainLoading}
      style={
        props.color ? { border: `3px solid ${props.color} !important` } : {}
      }
    ></Box>
  );
}
