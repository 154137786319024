import React, { useEffect } from "react";
import {
  makeStyles,
  Dialog,
  Box,
  Grid,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { SlQuestion } from "react-icons/sl";
import { useLocation, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    padding: "2.5px",
    backgroundImage:
      "linear-gradient(108.56deg, #EA9F28 16.26%, #CD851D 38.33%, #D29E2E 60.15%, #B37413 79.49%, #F1A72C 123.99%)",
    "& .root": {
      padding: "30px",
      position: "relative",
      borderRadius: "5px",
      backgroundColor: "#0A0807",
      overflow: "auto",
      paddingTop: "50px",
      paddingBottom: "40px",

      "& h4": {
        fontWeight: "600",
        color: "rgba(255, 255, 255, 0.87)",
        marginBottom: "30px",
        marginTop: "20px",
      },
    },
    "& .boxColor": {
      borderRadius: "10px",
      border: "1px solid rgb(87 77 77)",
      background: "#22201f",
      padding: "34px 0px",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      "& img": {
        width: "100%",
        maxWidth: "46px",
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "& p": {
        marginTop: "16px",
      },
    },
    "& .backBox": {
      position: "absolute",
      content: " ",
      borderRadius: "10px",
      top: "0",
      left: "0",
      background: "#0d0d0d",
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .imgBox": {
      width: "100%",
      maxWidth: "327px",
      [theme.breakpoints.down("xs")]: {
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
      },
    },
  },
  closeIcon: {
    position: "absolute",
    right: "0px",
    top: "0px",
    "& svg": {
      color: "#FFFFFF",
    },
  },
  dialogWidthSm: {
    maxWidth: "820px !important",
  },
  howToBox: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
    cursor: "pointer",
    "& svg": {
      color: "#a89f9f",
      cursor: "pointer",
    },
    "& h6": {
      color: "#a89f9f",
      marginLeft: "10px",
    },
  },
  backdrop: {
    backdropFilter: "blur(5px)",
  },
  MainToolClx: {
    "& img": {
      height: "150px",
      width: "100%",
    },
  },
}));

function ConnectWalletModal({ openModal, handleCloseModal, auth }) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location) {
      window.localStorage.setItem(
        "referralCode",
        location && location?.search?.split("?")[1]
      );
    }
  }, [location]);

  return (
    <>
      <Dialog
        open={openModal}
        maxWidth="sm"
        onClose={() => {
          !auth.metaMaskLoading && handleCloseModal();
          // history.push("/");
        }}
        fullWidth
        disableScrollLock
        PaperProps={{
          classes: {
            root: `${classes.dialogPaper} ${classes.dialogWidthSm}`,
          },
        }}
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
      >
        <Box className="root">
          <IconButton
            onClick={() => {
              !auth.metaMaskLoading && handleCloseModal();
              // history.push("/");
            }}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <img
                src="images/walletimg.png"
                alt=""
                width="100%"
                className="imgBox"
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography variant="h4">Connect your wallet</Typography>
              <Box
                className="boxColor"
                onClick={() => !auth.metaMaskLoading && auth.walletConnect()}
              >
                <Box>
                  <img src="images/metamask.png" alt="" />
                  <Typography variant="body1">Meta Mask</Typography>
                </Box>
                {auth.metaMaskLoading && (
                  <Box className="backBox">
                    <img
                      src="images/metmask.gif"
                      style={{ maxWidth: "250px" }}
                      alt="metamask"
                    />
                  </Box>
                )}
              </Box>
              <Tooltip
                title={
                  <Box className={classes.MainToolClx}>
                    <Typography variant="body2">
                      How to set up a crypto wallet
                    </Typography>
                    <Typography variant="body2">
                      1. Download the wallet browser extension
                    </Typography>
                    <img src="images/metaMaskHelpImage.png" alt="" />
                    <Typography variant="body2">
                      2. Open the browser extension and follow the prompts
                    </Typography>
                    <Typography variant="body2">
                      3. Create and store your seed phrase in a secure location
                    </Typography>
                  </Box>
                }
                arrow
                placement="auto"
              >
                <Box>
                  <Box
                    className={classes.howToBox}
                    onClick={() => window.open("/learnmore")}
                  >
                    <SlQuestion />
                    <Typography variant="h6">Learn how to connect</Typography>
                  </Box>
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
}

export default ConnectWalletModal;
