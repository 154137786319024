import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import { useLocation, useHistory } from "react-router-dom";
import { AuthContext } from "src/context/Auth";
import Footer from "./Footer";
import FreePlayLoginModal from "src/component/FreePlayLoginModal";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#141627",
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    // display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    // paddingTop: 56,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 230,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    // background: "rgba(2, 1, 3, 0.85)",
    background: "#141627",
    color: "#fff",
    minHeight: "calc(100vh - 62px)",
    padding: "60px 0px 0px",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    WebkitOverflowScrolling: "touch",
    padding: "50px 20px 0px",
    [theme.breakpoints.down("xs")]: {
      padding: "40px 16px 10px ",
    },
    "& .relativeClx": {
      position: "relative",
      zIndex: "11",
    },
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const location = useLocation();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.location.pathname === "/" && localStorage.getItem("ADMIN")) {
      history.push("/");
      localStorage.removeItem("ADMIN");
    }
    if (
      auth.ownerAddress !== auth.account &&
      localStorage.removeItem("ADMIN")
    ) {
      history.push("/");
      localStorage.removeItem("ADMIN");
    }
  }, [window.location.pathname, auth.account]);

  useEffect(() => {
    if (
      (location &&
        location?.search.split("?email=")[1] &&
        !sessionStorage.getItem("token")) ||
      !sessionStorage.getItem("token")
    ) {
      window.localStorage.setItem(
        "freePlayEml",
        location && location?.search.split("?email=")[1]
      );
      auth.setOpenFreePlayModal(true);
    }
  }, [location]);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content} id="main-scroll">
            {/* <img
              src="images/backgroundlayout.png"
              alt="image"
              className="layoubackgroundImg"
            /> */}
            <Box className="relativeClx">{children}</Box>
          </div>
        </div>
        <Footer />
      </div>
      <FreePlayLoginModal
        handleCloseModal={() => auth.setOpenFreePlayModal(false)}
        openModal={auth.openFreePlayModal}
        auth={auth}
      />
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
